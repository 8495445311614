import React from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import SEO from "../components/common/SEO";
import { H1, P } from "../components/common/Typography";
import imageHero from "../assets/images/hero-school.jpg";

const TITLE = "Η σχολή μας";

function SchoolPage() {
  return (
    <Layout>
      <SEO title={TITLE} />
      <img
        css={`
          width: 100%;
          vertical-align: bottom;
          max-height: 476px;
          object-fit: cover;
          object-position: bottom;
        `}
        src={imageHero}
        alt="School"
      />
      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Στην Ακαδημία Πλάτωνος, σε έναν μοναδικά ξεχωριστό χώρο, δημιουργήσαμε
          την πλέον σύγχρονη σχολή. Με την πλήρη ανακαίνιση μιας παλιάς
          βιοτεχνίας 600 τ.μ., διαμορφώσαμε τρεις υποδειγματικές αίθουσες
          διδασκαλίας (70 τ.μ., 80 τ.μ. και 180 τ.μ.), άνετες και λειτουργικές
          βοηθητικές εγκαταστάσεις, αλλά και ευχάριστους χώρους χαλάρωσης και
          ψυχαγωγίας.
        </P>
        <P>
          Εκτός από τις τυπικές προδιαγραφές που οφείλουμε να πληρούμε ως
          αναγνωρισμένη σχολή από το κράτος, φροντίσαμε ιδιαίτερα να
          εξασφαλίσουμε άριστες και ασφαλείς συνθήκες, τόσο για τους μαθητές όσο
          και για τους συνοδούς τους.
        </P>
      </Section.Main>
    </Layout>
  );
}

export default SchoolPage;
